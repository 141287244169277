import * as $ from "jquery";


$('#feedback-success-alert button').click(function () {
    $('.modal-wrap').css('display', 'none');
    $('body').find('#feedback-success-alert').css('display', 'none')
})

function setOrPush(target, val) {
    var result = val;
    if (target) {
        result = [target];
        result.push(val);
    }
    return result;
}

function getFormResults(formElement) {
    var formElements = formElement.elements;
    var formParams = {};
    var elem = null;
    for (var i = 0; i < formElements.length; i += 1) {
        elem = formElements[i];
        switch (elem.type) {
            case 'submit':
                break;
            case 'radio':
                if (elem.checked) {
                    formParams[elem.name] = elem.value;
                }
                break;
            case 'checkbox':
                if (elem.checked) {
                    formParams[elem.name] = setOrPush(formParams[elem.name], elem.value);
                }
                break;
            default:
                formParams[elem.name] = setOrPush(formParams[elem.name], elem.value);
        }
    }
    return formParams;
}

$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.btn-logout').on('click', function () {
        document.getElementById('logout-form').submit();
    });

    $('#login-form').submit(function (e) {
        e.preventDefault();

        var data = getFormResults(this);
        var form = $(this);

        $.ajax({
            type: 'POST',
            url: '/login',
            dataType: 'JSON',
            data: data,
            success: function (response) {
                window.location.href = response.redirect;
            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText).errors;

                form.find('span').hide();

                $.each(response, function (key, message) {
                    if (message) {
                        $('#login_' + key + '_error').html(message).show();
                    }
                });
            }
        })
    });

    $('#register-form').submit(function (e) {
        e.preventDefault();

        var data = getFormResults(this);
        var form = $(this);

        $.ajax({
            type: 'POST',
            url: '/register',
            dataType: 'JSON',
            data: data,
            success: function (response) {
                window.location.href = response.redirect;
            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText).errors;

                form.find('span').hide();

                $.each(response, function (key, message) {
                    if (message) {
                        $('#register_' + key + '_error').html(message).show();
                    }
                });
            }
        })
    });

    $('#feedback-form').submit(function (e) {
        e.preventDefault();

        var data = getFormResults(this);
        var form = $(this);

        $.ajax({
            type: 'POST',
            url: '/feedback/create',
            dataType: 'JSON',
            data: data,
            success: function (response) {
                $('.modal-wrap').css('display', 'flex');
                form.css('display', 'none');
                $('#feedback-success-alert').css('display', 'flex');
                $('#feedback-success-message').html(response.message);
                $('body').css('overflow', 'hidden');

                setTimeout(()=> {
                    $('.modal-wrap').css('display', 'none');
                    $('body').find('#feedback-success-alert').css('display', 'none')
                }, 5000);
            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText).errors;

                form.find('span').hide();

                $.each(response, function (key, message) {
                    if (message) {
                        $('#feedback_' + key + '_error').html(message).show();
                    }
                });
            }
        })
    })
})

$(document).ajaxSend(function () {
    $('#preloader').fadeIn();
});

$(document).on('ajaxSuccess ajaxError', function () {
    $('#preloader').fadeOut('slow');
});


$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
});

$('#site-feedback-form').on('submit', function (e) {
    e.preventDefault();
    var form = $(this);
    var url = form.attr('action');
    var data = getFormResults(this);
    $.ajax({
        type: 'POST',
        dataType: 'json',
        data: data,
        url: url
    });
})

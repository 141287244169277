import $ from "jquery";


export const fullscreenImage = () => {
    let target
    $('.diplomas-container img').click((e) => {
        target = `<div class="img-preview"><img src="${e.target.getAttribute('src')}"/></div>`
        $('body').append(target)
        $('.img-preview').click(() => {
            $('.img-preview').remove()
        })
        return target
    })
}
import  $ from "jquery";

function modal(item, target) {
    $(item).click(function () {
        $('.modal-wrap').css('display', 'flex');
        $(target).css('display', 'flex');
        $(window).scroll(function (e) {

        });
    });

    $(document).click(function (event) {
        if ($(event.target).closest('.modal-wrap').length !== 0 && $(event.target).closest('.action-form').length === 0) {
            $('.modal-wrap').css('display', 'none');
            $('body').find(target).css('display', 'none')

        }
    })
}

modal('.btn-login', '#login-form');
modal('.btn-register', '#register-form');
modal('.forgot-password', '#recovery-form');
modal('.trigger-feedback_modal', '#feedback-form');
modal('#deposit-trigger', '#deposit-container');

$('.btn-deposit').click(function () {
    $('.modal-wrap').css('display', 'flex');
    $('#deposit-container').css('display', 'none');
    $('#deposit').css('display', 'flex');
    $('body').css('overflow', 'hidden')
});

$(document).click(function (event) {
    if ($(event.target).closest('.modal-wrap').length !== 0 && $(event.target).closest('.action-form').length === 0) {
        $('.modal-wrap').css('display', 'none');
        $('body').css('overflow', 'auto').find('#deposit').css('display', 'none')
    }
})

$('.btn-gain').click(function () {
    $('.modal-wrap').css('display', 'flex');
    $('#deposit-container').css('display', 'none');
    $('#gain').css('display', 'flex');
    $('body').css('overflow', 'hidden')
});

$(document).click(function (event) {
    if ($(event.target).closest('.modal-wrap').length !== 0 && $(event.target).closest('.action-form').length === 0) {
        $('.modal-wrap').css('display', 'none');
        $('body').css('overflow', 'auto').find('#gain').css('display', 'none')
    }
})
$('.primary-item').click(function () {
    if ($(this).hasClass('active')) {
        $(this).removeClass('active').find('.secondary-list').slideUp('fast', function () {
        })
    } else {
        $('.primary-item').removeClass('active');
        $('.secondary-list').slideUp('fast', function () {
        });
        $(this).addClass('active').find('.secondary-list').slideDown('fast', function () {
        })
    }
});
$('.forgot-password').click(function () {
    $('.modal-wrap').css('display', 'flex');
    $('#login-form').css('display', 'none');
    $('#recovery-form').css('display', 'flex');
});

$('#deposit .btn-copy').click(function () {
    const copyText = document.getElementById('wallet')

    var range = document.createRange();
    range.selectNode(copyText);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();

    $('#deposit').html('<h2>Скопировано!</h2>')

})

$(document).click(function (event) {
    if ($(event.target).closest('.modal-wrap').length !== 0 && $(event.target).closest('.action-form').length === 0) {
        $('.modal-wrap').css('display', 'none');
        $('body').find('#recovery-form').css('display', 'none')
    }
})

$('#get-agreement').click(function () {
    $('.modal-wrap').css('display', 'flex');
    $('#register-form').css('display', 'none');
    $('#license-en').css('display', 'flex');
    $('.to-register').hide()
});

$(document).click(function (event) {
    if ($(event.target).closest('.modal-wrap').length !== 0 && $(event.target).closest('.action-form').length === 0) {
        $('.modal-wrap').css('display', 'none');
        $('body').find('#license-en').css('display', 'none')
        $('.to-register').show()
    }
})

$('.go-rus').click(function () {
    $('.modal-wrap').css('display', 'flex');
    $('#license-en').css('display', 'none');
    $('#license-ru').css('display', 'flex');
});

$(document).click(function (event) {
    if ($(event.target).closest('.modal-wrap').length !== 0 && $(event.target).closest('.action-form').length === 0) {
        $('.modal-wrap').css('display', 'none');
        $('body').find('#license-ru').css('display', 'none')
    }
})

$('.to-register').click(function () {
    $('.modal-wrap').css('display', 'flex');
    $('#license-en').css('display', 'none');
    $('#license-ru').css('display', 'none');
    $('#register-form').css('display', 'flex');
   
});

$(document).click(function (event) {
    if ($(event.target).closest('.modal-wrap').length !== 0 && $(event.target).closest('.action-form').length === 0) {
        $('.modal-wrap').css('display', 'none');
        $('body').find('#register-form').css('display', 'none')
    }
})


$('.license-open').click(function () {
    $('.modal-wrap').css('display', 'flex');
    $('#register-form').css('display', 'none');
    $('#license-en').css('display', 'flex');
    $('body').scroll((e) => {
        e.preventDefault()
        return false
    })
});


$(document).click(function (event) {
    if ($(event.target).closest('.modal-wrap').length !== 0 && $(event.target).closest('.action-form').length === 0) {
        $('.modal-wrap').css('display', 'none');
        $('body').find('#license-ru').css('display', 'none')
    }
})


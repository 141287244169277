import  $ from "jquery";
import './owl.carousel'

export const owlSetup = () => $('.hero .owl-carousel').owlCarousel({
    items: 1,
    loop: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 4000,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    autoWidth: false,
    lazyLoad: true,
    autoplaySpeed: 2000,
});

export const owlSetupFeedback = () => $('.feedback .owl-carousel').owlCarousel({
    items: 1,
    loop: true,
    autoplay: true,
    nav: true,
    autoplayTimeout: 6000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoWidth: false,
    lazyLoad: true,
    autoplaySpeed: 4000,
    autoplayHoverPause: true,
    margin: 25
});

import $ from 'jquery'

export const handleSubmitFeedback = () => {
    $('#submit-form').click(() => {
        localStorage.setItem('hasFeedback', '1')
        setTimeout(() => {
            $('#site-feedback-form').remove()
            $('.container.feedback-form__container').html('<h2>Спасибо Ваш отзыв важен для нас</h2>')
        })
    })
}
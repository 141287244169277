import $ from "jquery";
import '@fortawesome/fontawesome-free/js/all'
import {owlSetup, owlSetupFeedback} from "./landing/owl/owl.install";
import "./preloader";
import './landing/components/modal'
import {backEndPurpose} from "./for_backend";
import {fullscreenImage} from "./landing/components/fullscreenImage";
import "./landing/back_forms";
import 'bootstrap/dist/js/bootstrap'
import {handleSubmitFeedback} from "./landing/feedbackForm";


$(document).ready(function () {
    $('.hero').css('margin-top', $('header').innerHeight())
    owlSetup()
    owlSetupFeedback()
    fullscreenImage()
    backEndPurpose()


    handleSubmitFeedback()

    if(!!localStorage.getItem('hasFeedback')){
        $('.container.feedback-form__container').remove()
    }
})

$(function () {
    $('[data-toggle="tooltip"]').tooltip({})
})


